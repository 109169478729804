import ElectionUpload from "../electionconfig/electionUpload.tsx"

function ElectionConfig() {
  const electionId = 1;
  return (
    <ElectionUpload electionId={electionId}/>
  );
}

export default ElectionConfig;
