import React from 'react';
// import { Link } from 'react-router-dom'
import {
  Header, HeaderContent, HeaderBrand,
}  from 'design-react-kit';

const HeaderCenter = () => {
    return(
        <Header
          small
          theme="light"
          type="center"
        >
          <HeaderContent>
            <HeaderBrand iconName="it-code-circle">
              <h2>
                  VotoRemoto
              </h2>
              <h3>
                  Le tue elezioni scolastiche, facili e da remoto.
              </h3>
            </HeaderBrand>

          </HeaderContent>
        </Header>
    )
}

export default HeaderCenter;