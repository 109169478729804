import {Card, CardBody, CardText, CardTitle, CardReadMore} from "design-react-kit";

const ElectionDescribeCard = ({title, status}) => {
    const statusMapping = {
        1: "L'elezione non è ancora stata caricata",
        2: "L'elezione è stata caricata e creata, sarà possibile votare dal",
        3: "L'elezione è in corso",
        4: "L'elezione è terminata"
    };
    const statusActionMapping = {
        1: "Crea elezione",
        2: "Modifica elezione",
        3: "Guarda le statistiche",
        4: "Risultati"
    };
    const statusLinkToMapping = {
        1: `/election/config/${status}`,
        2: `/election/config/${status}`,
        3: `/election/stats/${status}`,
        4: `/election/results/${status}`
    };
    return (
        <Card spacing className='card-bg card-big no-after'>
            <CardBody>
                <CardTitle tag='h5'>
                  {title}
                </CardTitle>
                <CardText>
                  {statusMapping[status]}
                </CardText>
                <CardReadMore
                    href={statusLinkToMapping[status]}
                    iconName="it-arrow-right"
                    tag="a"
                    text={statusActionMapping[status]}
                />
            </CardBody>
        </Card>
    )
}

export default ElectionDescribeCard;