import React from 'react';
// import { Link } from 'react-router-dom'
import {
  Header, HeaderContent, HeaderBrand
}  from 'design-react-kit';

const SlimHeader = ({school_name}) => {
    return(
        <Header
          theme="light"
          type="slim"
        >
          <HeaderContent>
            <HeaderBrand href={'https://votoremoto.it'}>
                {school_name}
            </HeaderBrand>
          </HeaderContent>
        </Header>
    )
}

export default SlimHeader;