import React from 'react';
// import { Link } from 'react-router-dom'
import {
  Header, HeaderContent, LinkList, UncontrolledDropdown, Icon, LinkListItem, DropdownToggle, DropdownMenu, Nav, NavItem,
}  from 'design-react-kit';

const HeaderNav = () => {
    return(
        <Header
          theme="light"
          type="navbar"
        >
          <HeaderContent
            expand="lg"
          >
            <div className="menu-wrapper">
              <Nav navbar>
                <NavItem>
                  <UncontrolledDropdown
                    nav
                    tag="div"
                  >
                    <DropdownToggle
                      caret
                      nav
                    >
                      Docenti
                      <Icon icon="it-expand" />
                    </DropdownToggle>
                    <div className='docs-show-dropdown-open'>
                      <DropdownMenu className={'link-list'}>
                        <LinkList>
                          <LinkListItem href={"/election/config/1"}>
                            <span>Carica dati</span>
                          </LinkListItem>
                          <LinkListItem>
                            <span>Stato elezione</span>
                          </LinkListItem>
                          <LinkListItem divider />
                          <LinkListItem>
                            <span>Risultati</span>
                          </LinkListItem>
                        </LinkList>
                      </DropdownMenu>
                    </div>
                  </UncontrolledDropdown>
                </NavItem>
                <NavItem>
                  <UncontrolledDropdown
                    nav
                    tag="div"
                  >
                    <DropdownToggle
                      caret
                      nav
                    >
                      ATA
                      <Icon icon="it-expand" />
                    </DropdownToggle>
                    <div className='docs-show-dropdown-open'>
                      <DropdownMenu>
                        <LinkList>
                          <LinkListItem>
                            <span>Carica dati</span>
                          </LinkListItem>
                          <LinkListItem>
                            <span>Stato elezione</span>
                          </LinkListItem>
                          <LinkListItem divider />
                          <LinkListItem>
                            <span>Risultati</span>
                          </LinkListItem>
                        </LinkList>
                      </DropdownMenu>
                    </div>
                  </UncontrolledDropdown>
                </NavItem>
                <NavItem>
                  <UncontrolledDropdown
                    nav
                    tag="div"
                  >
                    <DropdownToggle
                      caret
                      nav
                    >
                      Genitori
                      <Icon icon="it-expand" />
                    </DropdownToggle>
                    <div className='docs-show-dropdown-open'>
                      <DropdownMenu>
                        <LinkList>
                          <LinkListItem>
                            <span>Carica dati</span>
                          </LinkListItem>
                          <LinkListItem>
                            <span>Stato elezione</span>
                          </LinkListItem>
                          <LinkListItem divider />
                          <LinkListItem>
                            <span>Risultati</span>
                          </LinkListItem>
                        </LinkList>
                      </DropdownMenu>
                    </div>
                  </UncontrolledDropdown>
                </NavItem>
                <NavItem>
                  <UncontrolledDropdown
                    nav
                    tag="div"
                  >
                    <DropdownToggle
                      caret
                      nav
                    >
                      Studenti
                      <Icon icon="it-expand" />
                    </DropdownToggle>
                    <div className='docs-show-dropdown-open'>
                      <DropdownMenu>
                        <LinkList>
                          <LinkListItem>
                            <span>Carica dati</span>
                          </LinkListItem>
                          <LinkListItem>
                            <span>Stato elezione</span>
                          </LinkListItem>
                          <LinkListItem divider />
                          <LinkListItem>
                            <span>Risultati</span>
                          </LinkListItem>
                        </LinkList>
                      </DropdownMenu>
                    </div>
                  </UncontrolledDropdown>
                </NavItem>
              </Nav>
            </div>
          </HeaderContent>
        </Header>
    )
}

export default HeaderNav;