
import XlsxUploaderCard from "./elements/xlsxUploaderCard.tsx";
import {
  Section, Container, Row, Col
} from 'design-react-kit';


export default function ElectionUpload(electionId) {
  return (
      <Section>
          <Container>
            <Row>
              <Col>
                <XlsxUploaderCard electionId={electionId}/>
              </Col>
            </Row>
          </Container>
      </Section>
  );
}
