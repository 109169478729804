// import {FaPhone, FaMailBulk} from "react-icons/fa"
import {Container, Row, Col, LinkList, LinkListItem, Icon} from 'design-react-kit';

const Footer = ()=>{
    return (
        <footer className="it-footer">
          <div className="it-footer-main">
            <Container>
              <section>
                <Row className="justify-content-md-center" >
                  <Col sm={4}>
                    <div className="it-brand-wrapper">
                      <a
                        className=""
                        href="/"
                      >
                        <Icon icon="it-software" />
                        <div className="it-brand-text">
                          <h2>
                            VotoRemoto
                          </h2>
                          <h3 className="d-none d-md-block">
                            Le tue elezioni scolastiche, facili e da remoto.
                          </h3>
                        </div>
                      </a>
                    </div>
                  </Col>
                </Row>
              </section>
              <section className="border-white border-top">
                <Row className="justify-content-center">
                  <Col size={"4"}
                  >
                    <h4>
                      <a
                        href="/"
                        title="Vai alla home"
                      >

                        VotoRemoto
                      </a>
                    </h4>
                    <p>
                      <strong>
                        Di Mario Mureddu
                      </strong>
                      <br />
                      Piazza IV Novembre 37 - Quartu Sant'Elena
                      <br />
                      P. IVA: 03791190923
                    </p>
                    <LinkList className="footer-list clearfix">
                      <LinkListItem
                        href="mailto:info@votoremoto.it"
                        title="Contataci via mail"
                      >
                        <div>
                          <Icon
                            className="align-top"
                            color="white"
                            icon="it-mail"
                            size="sm"
                          />
                            Posta Elettronica
                        </div>
                      </LinkListItem>
                    </LinkList>
                  </Col>
                  <Col
                      size={"4"}
                  >
                    <h4>
                      <a>
                        Link Utili
                      </a>
                    </h4>
                    <LinkList className="footer-list clearfix">
                      <LinkListItem
                        href="note-legali"
                        title="Note legali"
                      >
                        Note Legali
                      </LinkListItem>
                      <LinkListItem
                        href="note-legali"
                        title="Note legali"
                      >
                        Privacy Policy
                      </LinkListItem>
                    </LinkList>
                  </Col>

                </Row>
              </section>
            </Container>
          </div>
        </footer>
    )
}

export default Footer;