import React from 'react';
// import { Link } from 'react-router-dom'
import {Headers}  from 'design-react-kit';
import SlimHeader from "./headers/SlimHeader";
import HeaderCenter from "./headers/HeaderCenter";
import HeaderNav from "./headers/HeaderNav"

const Navbar = () => {
    return(
        <Headers
            sticky={true}
        >
          <SlimHeader theme="light" />
          <div className="it-nav-wrapper">
            <HeaderCenter
                theme="light"
                school_name={'Prova'}
                school_place={"Vattelapesca"}
            />
            <HeaderNav theme="light" />
          </div>
        </Headers>
    )
}

export default Navbar;