import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <Auth0Provider
          clientId={process.env.REACT_APP_Auth0ClientId}
          domain={process.env.REACT_APP_Auth0Domain}
          redirectUri={window.location.origin}
          audience={process.env.REACT_APP_Auth0Audience}
          scope="read:current_user update:current_user_metadata"
      >
          <App />
      </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();
