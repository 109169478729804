import { HTMLAttributes } from "react";

export default function UploadWrapper(props: HTMLAttributes<HTMLDivElement>) {
  return (

    <div
      className="bg-gray-50 border-slate-300 border-dashed border rounded-2xl w-[28rem] h-[16rem] flex flex-col items-center justify-center gap-6"
      {...props}
    >
      {props.children}
    </div>
  );
}
