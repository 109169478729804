import {
    Container, Row, Col, Section
} from 'design-react-kit';
import ElectionDescribeCard from "../elements/electionDescribeCard"

function Home() {

  return (
      <Section>
          <Container>
              <Row>
                  <Col>
                    <ElectionDescribeCard
                        title={"Elezione Docenti"}
                        status={1}
                    />
                  </Col>
                  <Col>
                    <ElectionDescribeCard
                        title={"Elezione Personale ATA"}
                        status={2}
                    />
                  </Col>
                  <Col>
                    <ElectionDescribeCard
                        title={"Elezione Genitori"}
                        status={3}
                    />
                  </Col>
                  <Col>
                    <ElectionDescribeCard
                        title={"Elezione Studenti"}
                        status={4}
                    />
                  </Col>
              </Row>
          </Container>
      </Section>

  );
}

export default Home;
