import {
    Button, Card, CardBody, CardFooterCTA, CardSignature, CardText, CardTitle, Icon, Progress, Input
} from "design-react-kit";
import UploadWrapper from './UploadWrapper.tsx'
import {ChangeEventHandler, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import uploadS3 from "../../../../utils/uploadS3.ts";
import {Check2Square} from "react-bootstrap-icons";

enum FileState {
  IDLE,
  UPLOADING,
  DONE,
}

type GetPresignedURLResponse = {
  message: { process_id: string; s3_url_response: string };
};


const FILE_SIZE_LIMIT = 50 * 1024 * 1024;

export default function XlsxUploaderCard(electionId) {
    let [fileState, setFileState] = useState(FileState.IDLE);
    let { getAccessTokenSilently, user } = useAuth0();
    let [progress, setProgress] = useState(0);

    const xlsxUploadUrlMapping = {
        1: "schooladmin/load_voters_excel",
        2: "schooladmin/load_candidates_excel"
    }

    const bucketDirMapping = {
        1: "voters",
        2: "candidates"
    }

    const loadingUserTypeMapping = {
        1: "degli elettori:",
        2: "dei candidati:"
    }

    const handleFileChange: ChangeEventHandler<HTMLInputElement> = async (ev) => {
        let file = ev.target.files ? ev.target.files[0] : null;
        if (file === null) return;
        if (file.size > FILE_SIZE_LIMIT)
          return alert("Il file è troppo grande - max 50MB");
        setFileState(FileState.UPLOADING);
        // when file is loaded on interface, start by getting a S3 presigned url
        // let user_id = encodeURI(user?.sub || "");
        console.log(`${process.env.REACT_APP_BEDomain}/${xlsxUploadUrlMapping[electionId.electionId.electionId]}`)
        console.log(user)
        let token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_Auth0Audience,
          scope: "read:current_user",
        });
        let response = await fetch(
          `${process.env.REACT_APP_BEDomain}/${xlsxUploadUrlMapping[electionId.electionId.electionId]}`,
          {
            headers: {
                Authorization: `Bearer ${token}`
            },
            // body: JSON.stringify({ user_id: user?.sub }),
          }
        );

        // console.log(await response.text())
        let json: GetPresignedURLResponse = await response.json();


        sessionStorage.setItem("process_id", json.message.process_id);
        let newName = `user_files/${bucketDirMapping[electionId.electionId.electionId]}/${json.message.process_id} + ".xlsx`;
        // Rename the file before uploading
        let fileToUpload = new File([file], newName, {
          type: file.type,
          lastModified: file.lastModified,
        });
        let url = json.message.s3_url_response;
        // url = url.replace(`/${newName}`, "");
        try {
          await uploadS3(url, fileToUpload, (progress) => setProgress(progress));
        } catch (e) {
          alert("si è verificato un errore, riprova più tardi");
          setProgress(0);
          setFileState(FileState.IDLE);
          return;
        }
        setFileState(FileState.DONE);
        };
    return (
        <Card spacing className='card-bg card-big no-after'>
          <CardBody>
            <CardTitle tag='h5'>
                {`Carica il file excel ${loadingUserTypeMapping[electionId.electionId.electionId]}`}
            </CardTitle>
            <CardText>
              Puoi scaricare il template QUI (inserire link)
            </CardText>
            {fileState === FileState.IDLE ? (
                <label
                    className="relative"
                    style={{
                            width:"50%",
                            height:"40%"
                        }}
                >
                    <UploadWrapper
                        style={{
                            width:"99%",
                            height:"99%",
                            border:"2px solid",
                            borderRadius:"5px"
                        }}
                    >
                        <br/>
                        <Icon icon={'it-upload'}/>
                        <span className="text-xs text-slate-400">
                          <br/>Trascina qui il tuo file<br/>
                        </span>
                        <input
                          type="file"
                          className="absolute top-0 left-0 bottom-0 right-0 w-full h-full opacity-0"
                          onChange={handleFileChange}
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        />
                    </UploadWrapper>
                </label>
                // <Input
                //     style={{
                //         width:"30%",
                //         height:"80%",
                //         border:"2px solid",
                //         "border-radius":"5px"
                //     }}
                //     type="file"
                //     bsSize="lg"
                //     className="absolute top-0 left-0 bottom-0 right-0 w-full h-full opacity-0"
                //     onChange={handleFileChange}
                //     accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                // />
            ) : null}
            {fileState === FileState.UPLOADING ? (
                <UploadWrapper>
                  <Icon icon={'it-upload'}/>
                  <Progress value={progress} />
                </UploadWrapper>
            ) : null}
            {fileState === FileState.DONE ? (
                <UploadWrapper>
                  <Check2Square/>
                  <span className="text-xs font-extrabold text-green-400">
                    Caricamento completato!
                  </span>
                </UploadWrapper>
            ) : null}

            <CardFooterCTA>
              <CardSignature>
                  Completa il caricamento {loadingUserTypeMapping[electionId.electionId.electionId]}
              </CardSignature>
              <Button outline color='primary'>
                Carica
              </Button>
            </CardFooterCTA>
          </CardBody>
        </Card>
    )
}