import React, { Component } from 'react';
import { Route, Router, Switch } from 'wouter';
import Navbar from './components/Navbar'
import Home from './components/routes/Home'
import RequireAuth from "./components/RequireAuth";
import Footer from "./components/Footer";
import ElectionConfig from "./components/routes/electionconfig/electionConfig";
// import RequireAuth from "./components/RequireAuth";
// import Console from "./components/Console";
// import './css/mystyles.css'
import './App.css';
// import { Alert } from 'design-react-kit';
import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';

class App extends Component {
  render() {
    return (
       <Router>
            <div className="App">
                <Navbar
                />
                <Switch>
                    <Route exact path="/" component={RequireAuth(Home)}/>
                    <Route path="/election/config/:electionId" component={RequireAuth(ElectionConfig)}/>
                    {/*<Route path="/shop" component={Shop}/>*/}
                    {/*<Route path="/contact" component={Contact}/>*/}
                    {/*<Route path="/console" component={RequireAuth(Console)} />*/}
                </Switch>
                <Footer/>
             </div>
       </Router>

    );
  }
}

export default App;